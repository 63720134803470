import * as React from "react";
import { EXTERNAL_ROUTE_LINKS, ROUTE_LINKS } from "../utils/routes";
import PageWrapper from "../components/PageWrapper";
import Paragraph from "../components/Paragraph";
import SEO from "../components/SEO";
import Button from "../components/Button";
import EmbedModal from "../components/EmbedModal";
import RenterLandingImage from "../images/renter-landing.jpg";
import { HUBSPOT_FORMS } from "../utils/constants";
import MAnchor from "../components/MAnchor";

const LandLordLanding: React.FC = () => {
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [dialogData, setDialogData] = React.useState({
    embedUrl: "",
    header: "",
  });

  return (
    <PageWrapper path={ROUTE_LINKS.renterLanding}>
      <SEO pageUrl={ROUTE_LINKS.renterLanding} />
      <main className="flex flex-col md:flex-row max-w-6xl md:mx-auto md:items-center p-2.5 pb-12 justify-between min-h-[700px]  md:min-h-[555px]">
        <div className="w-full md:w-5/12">
          <Paragraph title="Ready for an upgrade?">
            Simplify your home rental journey - Matrix Rental Solutions provides one application for you to submit all your information to find your new home.
            <ul className="list-disc ml-8 font-bold">
              <li>One Application</li>
              <li>One Tenant Screening</li>
              <li>One-time Application Fee</li>
            </ul>
          </Paragraph>

          <div className="mt-16 flex flex-col">
            <Paragraph title="">
                Matrix gives residents 30 Days of application access to apply to any rental in our network.
            </Paragraph>
            <Button
              type="button"
              className="py-1 px-4 mt-5 w-max"
              onClick={() => {
                setDialogData({
                  embedUrl: HUBSPOT_FORMS.renterLearnMore,
                  header: "Learn More",
                });
                setDialogVisible(true);
              }}
            >
              Learn More
            </Button>
            <Button
              type="button"
              className="py-1 px-4 mt-5 w-max"
              onClick={() => {
                setDialogData({
                  embedUrl: HUBSPOT_FORMS.renterLearnMore,
                  header: "Apply now",
                });
                setDialogVisible(true);
              }}
            >
              Apply Now
            </Button>
          </div>
        </div>
        <div className="w-full md:w-6/12 mt-4 md:mt-0">
          <img
            src={RenterLandingImage}
            alt="Matrix Model"
            title="Matrix Model"
            className="mx-auto"
          />
        </div>
      </main>
      <EmbedModal
        data={dialogData}
        isVisible={dialogVisible}
        setIsVisible={() => setDialogVisible(false)}
      />
    </PageWrapper>
  );
};

export default React.memo(LandLordLanding);
